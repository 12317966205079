<template>
    <div id="dialog-order-edit">
        <v-dialog v-model="orderDialog" max-width="800" max-height="640" :fullscreen="$vuetify.breakpoint.xsOnly" scrollable @click:outside="orderDialog = false" @keydown.esc="orderDialog = false">
            <v-card :loading="orderUpdateFlag">
                <v-card-title class="grey lighten-2">
                    {{ $vuetify.breakpoint.xsOnly ? header.substring(0, 24) + '...' : header }}
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        @click="orderDialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'px-1' : 'px-3'">
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="12" sm="7">
                                            <v-text-field v-if="!order.isCombined" readonly :value="order.point.address" label="Адрес точки (этот адрес видит курьер)" hide-details></v-text-field>
                                            <v-text-field 
                                                v-else 
                                                v-for="childOrder in getPointSequence" 
                                                :key="childOrder.id" 
                                                readonly 
                                                :value="childOrder.point.address" 
                                                label="Адрес точки (этот адрес видит курьер)" 
                                                hide-details
                                            ></v-text-field>

                                            <v-text-field :readonly="!userOperator && !order.isCombined" v-model="order.address" label="Адрес гостя (этот адрес видит курьер)" hide-details></v-text-field>
                                            <div class="d-flex flex-row">
                                                <v-text-field v-if="userOperator" :readonly="order.isCombined" class="mr-8 input-price" append-icon="mdi-currency-rub" :value="orderPrice(order.feeCourier)" @input="onInputPriceCourier" label="Цена курьера" hide-details></v-text-field>

                                                <v-text-field class="mr-8 input-price" :readonly="!userOperator || order.isCombined" append-icon="mdi-currency-rub" :value="orderPrice(order.feePoint)" @input="onInputPricePoint" :label="userOperator ? 'Цена точки' : 'Цена доставки'" hide-details></v-text-field>

                                                <v-text-field readonly :value="distance(order.distance)" label="Расстояние" hide-details></v-text-field>
                                            </div>

                                            <v-row class="mt-0">
                                                <v-col cols="6" sm="7">
                                                    <p v-if="userOperator" class="mb-0 text-caption text--primary">
                                                        Время на забор: <b class="font-weight-medium">{{ timeTake }} м</b>
                                                    </p>
                                                    <p v-if="userOperator" class="mb-2 text-caption text--primary">
                                                        Время на доставку: <b class="font-weight-medium">{{ timeDelivery }} м</b>
                                                    </p>
                                                    <p class="mb-1 text--primary">
                                                        🕓 Создан: <b class="font-weight-medium">{{ timeToString(order.createdAt) }}</b>
                                                    </p>

                                                    <p class="mb-1 text--primary">
                                                        {{ getOrderMode(order, true) }} Забрать: <b class="font-weight-medium">до {{ timeToString(order.dateReady) }}</b>
                                                    </p>

                                                    <p class="mb-1 text--primary">
                                                        🏁 Доставить: <b class="font-weight-medium">к {{ timeToString(order.dateDelivery) }}</b>
                                                    </p>
                                                </v-col>

                                                <v-col cols="6" sm="5" class="d-flex flex-column align-center justify-center">
                                                    <v-tooltip bottom open-delay="800">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span class="tooltip-text" v-bind="attrs" v-on="on">
                                                                <v-btn v-if="userOperator && !order.isCombined" :disabled="orderUpdateFlag" small class="ma-1 mr-3 btn-update" @click="onClickAddressFound" hide-details> 🔍 Найти место </v-btn>
                                                            </span>
                                                        </template>
                                                        <span>Найти место по адресу гостя и обновить стоимость доставки</span>
                                                    </v-tooltip>

                                                    <v-tooltip bottom open-delay="800">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span class="tooltip-text" v-bind="attrs" v-on="on">
                                                                <v-btn v-if="userOperator" :disabled="orderUpdateFlag || order.status !== OrderStatus.new" small class="ma-1 mr-3 btn-update" @click="onClickTimesUpdate" hide-details> 🕓 Обновить время </v-btn>
                                                            </span>
                                                        </template>
                                                        <span>Обновить время когда забрать и когда доставить</span>
                                                    </v-tooltip>

                                                    <v-tooltip bottom open-delay="800">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span class="tooltip-text" v-bind="attrs" v-on="on">
                                                                <v-btn
                                                                    v-if="userOperator && !order.isCombined"
                                                                    :disabled="orderUpdateFlag || distanceUpdateFlag"
                                                                    small
                                                                    class="ma-1 mr-3 btn-update"
                                                                    @click="onClickDistance"
                                                                    hide-details
                                                                >
                                                                    📏 Задать км
                                                                </v-btn>
                                                            </span>
                                                        </template>
                                                        <span>Обновить время когда забрать и когда доставить</span>
                                                    </v-tooltip>

                                                    <v-tooltip bottom open-delay="800">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span class="tooltip-text" v-bind="attrs" v-on="on">
                                                                <v-btn :disabled="orderUpdateFlag" small class="ma-1 mr-3 btn-update" @click="onClickRoute" hide-details> 🗺 Маршрут </v-btn>
                                                            </span>
                                                        </template>
                                                        <span>Посмотреть маршрут следования курьера</span>
                                                    </v-tooltip>
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-col cols="12" sm="5" class="d-flex align-center justify-center">
                                            
                                            <address-widget
                                                header="Укажите адрес или место доставки"
                                                status-positive="✓ Место доставки определено"
                                                status-negative="❗️ Место доставки не определено"
                                                tooltip-text="Нажмите, чтобы указать на карте место доставки"
                                                :height="240"
                                                :read-only="userIsPoint == true"
                                                :default-latitude="$store.state.user.city.latitude"
                                                :default-longitude="$store.state.user.city.longitude"
                                                :address="order.address"
                                                :latitude="order.latitude"
                                                :longitude="order.longitude"
                                                @update="onAddressWidgetUpdate"
                                            ></address-widget>
                                            
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col cols="6">
                                    <v-text-field class="mt-2" :readonly="!userOperator || order.isCombined" v-model="order.customerString" label="Гость" hide-details></v-text-field>
                                    <v-text-field class="mt-2" :readonly="!userOperator || order.isCombined" v-model="order.payCost" label="Цена заказа, ₽" hide-details></v-text-field>
                                    <v-text-field class="mt-2" :readonly="!userOperator || order.isCombined" v-model="order.commentOperator" label="Комментарий от оператора" hide-details></v-text-field>
                                </v-col>

                                <v-col cols="6" v-if="!order.isCombined">
                                    <v-text-field class="mt-2" :readonly="!userOperator || order.isCombined" v-model="order.commentPoint" label="Комментарий от точки" hide-details></v-text-field>
                                    <v-text-field class="mt-2" :readonly="!userOperator || order.isCombined" v-model="order.commentCourier" label="Комментарий от курьера" hide-details></v-text-field>
                                    <v-text-field class="mt-2" :readonly="!userOperator || order.isCombined" v-model="order.commentCustomer" label="Комментарий от гостя" hide-details></v-text-field>
                                </v-col>

                                <v-col cols="6" v-else>
                                    <v-textarea v-for="childOrder in order.orders" :key='childOrder.id' rows="3" :readonly="!userOperator" v-model="childOrder.descr" :label=setLabel(childOrder) hide-details></v-textarea>
                                </v-col>

                                <v-col v-if="userOperator && order.courierId" cols="12" sm="6">
                                    <v-autocomplete :disabled="order.status !== OrderStatus.delivered" class="flex-grow-0" no-data-text="Нет данных" :items="couriersList" name="courier" item-text="name" item-value="id" v-model="order.courierId" label="Доставка курьером" persistent-hint hide-details></v-autocomplete>
                                </v-col>

                                <v-col v-if="userOperator && !order.courierId && !order.isCombined && order.status !== OrderStatus.forming" cols="12" sm="6">
                                    <v-btn :disabled="orderUpdateFlag" class="mx-2 my-2" color="success" dark @click="dialogCourierComplete = true">Доставка курьером</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <!-- TODO Временно отключена возможность точкам супры создавать заказы, так как курьеры используют кабинет -->

                <v-card-actions>
                        <v-row no-gutters>

                            <v-col cols="6" md="3" class="d-flex align-center justify-center" v-if="!order.isCombined">
                                <v-btn v-if="$store.state.user.settings.city === 'Владивосток' && userOperator && point.settings.darallPointIdNum" :disabled="orderUpdateFlag || order.status !== OrderStatus.new" class="mx-2 my-2 btn-darall" color="purple" :dark="order.status === OrderStatus.new" @click="onClickSend">Ускорить!<br>➡ в Darall</v-btn>
                            </v-col>
                            <!-- <v-col cols="6" md="3" class="d-flex align-center justify-center" v-else>
                                <v-btn 
                                    v-if="$store.state.user.settings.city === 'Владивосток' && userOperator && Array.isArray(this.multiOrderPointsDarall) && this.multiOrderPointsDarall.length === this.order.orders.length" 
                                    :disabled="orderUpdateFlag || order.status !== OrderStatus.new" 
                                    class="mx-2 my-2" color="purple" 
                                    :dark="order.status === OrderStatus.new" 
                                    @click="onClickSend">
                                    ➡ в Darall
                                </v-btn>
                            </v-col> -->
                            <v-col cols="6" md="3" class="d-flex align-center justify-center">
                                <v-btn v-if="userOperator" :disabled="orderUpdateFlag || order.status === OrderStatus.forming" class="mx-2 my-2" color="success" dark @click="onClickSave">Сохранить</v-btn>
                            </v-col>
                            <v-col cols="6" md="3" class="d-flex align-center justify-center">
                                <v-btn :disabled="orderUpdateFlag || ($store.state.userIsPoint && $store.state.user.point.name.includes('Супра')) || this.order.isCombined" class="mx-2 my-2" color="purple" text @click="onClickCopy">+ Копировать</v-btn>
                            </v-col>
                            <v-col cols="6" md="3" class="d-flex align-center justify-center">
                                <v-btn v-if="userOperator" :disabled="orderUpdateFlag || deleteDisabled" class="mx-2" color="error" text @click="onClickDelete">Удалить</v-btn>
                            </v-col>

                        </v-row>

                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogCourierComplete" width="540">
            <v-card>
                <v-card-title class="grey lighten-2">Выбор курьера</v-card-title>

                <v-card-text class="py-2">
                    <span>Выберите курьера, который доставил заказ:</span>
                </v-card-text>

                <v-card-text class="py-2">
                    <v-autocomplete :items="couriersList" :value="selectedCourierId" label="Начните вводить имя или выберите из списка" class="ma-0 pa-0" item-value="id" item-text="name" persistent-hint return-object single-line hide-details solo @change="onSelectCourier"></v-autocomplete>
                </v-card-text>
            
                <v-card-text class="py-3 d-flex">
                    <v-icon color="orange">mdi-information-outline</v-icon>
                    <span style="color: #ff9800" class="ml-2 my-1 text-caption">Статус заказа изменится на <b>Доставлен</b>.</span>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" color="success" @click="onClickCourierComplete" :disabled="!selectedCourierId">Принять</v-btn>
                    <v-btn class="mx-2" color="primary" text @click="dialogCourierComplete = false">Отменить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="promptDarallSend" width="500">
            <v-card :loading="loadingDarallSend">
                <v-card-title class="grey lighten-2"> Отправка заказа </v-card-title>

                <v-card-text class="py-4">
                    <span>Вы уверены, что хотите <b>отправить заказ {{ order.name }} оператору Darall</b>?</span>
                    <br /><br />
                    <span>Стоимость доставки: <b>{{
                        loadingDarallSend ?
                        'подождите, идет загрузка...' :
                        (darallPricePoint ? `${darallPricePoint} ₽` : 'не определена, будет задана оператором Darall')
                    }}</b></span><br />
                    <span>Когда забрать: <b>{{
                        loadingDarallSend ?
                        '--' :
                        (darallDateReady ? `${timeToString(darallDateReady)}` : 'не определено, будет задано оператором Darall')
                    }}</b></span><br />
                    <span>Когда доставить: <b>{{
                        loadingDarallSend ?
                        '--' :
                        (darallDateDelivery ? `${timeToString(darallDateDelivery)}` : 'не определено, будет задано оператором Darall')
                    }}</b></span><br />
                    <br />
                    <span>Времена забора и доставки заказа, а также стоимость могут быть пересчитаны <b>оператором Darall</b>.</span>
                    <br /><br />
                    <span>После отправки заказ станет недоступен в этом кабинете, но будет доступен <b>в личном кабинете Darall</b> и в <a href="https://t.me/DarallBot">Телеграм боте Darall</a>.</span>
                    <br /><br />
                    <span class="red--text">Внимание: отправленный заказ нельзя будет вернуть!</span>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="loadingDarallSend" class="mx-2" color="success" dark @click="promptDarallSendYes">Отправить</v-btn>
                    <v-btn :disabled="loadingDarallSend" class="mx-2" color="error" text @click="promptDarallSend = false">Отмена</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="promptDelete" width="540">
            <v-card>
                <v-card-title class="grey lighten-2">
                    Удаление заказа
                    <v-spacer></v-spacer>
                    <!-- <v-btn
                        icon
                        @click="promptDelete = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn> -->
                </v-card-title>

                <v-card-text class="py-4">
                    <span>Вы действительно хотите удалить заказ <b>{{ getOrderName(order, point) }}</b> ?</span>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" color="error" text @click="promptDelete = false">Нет</v-btn>
                    <v-btn class="mx-2" color="success" text @click="promptDeleteYes">Да</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="promptDistance" width="540">
            <v-card>
                <v-card-title class="grey lighten-2">
                    Изменить расстояние
                    <v-spacer></v-spacer>
                </v-card-title>

                <v-card-text class="py-4">
                    <span>Введите <b>расстояние от точки забора до места доставки в километрах</b>:</span>
                </v-card-text>

                <v-card-text class="py-2">

                    <v-text-field class="mt-2 mx-3" v-model="promptDistanceValue" label="Расстояние, км" hide-details @keydown.enter="promptDistanceYes"></v-text-field>

                    
                    <p style="color: #ff9800" class="ml-2 my-5 text-caption">
                        <v-icon color="#ff9800">mdi-information-outline</v-icon>
                        Стоимость доставки для точки и курьера обновится автоматически.
                    </p>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        class="mx-2"
                        color="success"
                        @click="promptDistanceYes"
                        :disabled="distanceUpdateFlag"
                        :loading="distanceUpdateFlag"
                        >Принять</v-btn
                    >

                    <v-btn
                        class="mx-2"
                        color="primary"
                        text
                        @click="promptDistance = false"
                        :disabled="distanceUpdateFlag"
                        >Отменить</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import config from '../../config'
    import moment from 'moment-timezone'
    import addressWidget from '../address_input/Widget.vue'

    moment.locale('ru')

    import DateTime from '../../mixins/DateTime.js'
    import Notify from '../../mixins/Notify.js'
    import Orders from '../../mixins/Orders.js'

    export default {
        name: 'dialog-order-edit',
        components: {
            addressWidget,
        },
        mixins: [DateTime, Notify, Orders],
        props: {
            data: {
                type: Object,
                required: true,
            },
            settings: {
                type: Object,
                required: true,
            },
            points: {
                type: Array,
            },
            couriers: {
                type: Array,
            },
            userOperator: {
                type: Boolean,
                default: false,
            },
            deleteDisabled: {
                type: Boolean,
                default: false,
            },
            header: {
                type: String,
                default: '',
            },
        },
        emits: ['save', 'copy', 'delete', 'close', 'send-complete'],
        computed: {
            getPointSequence() {
                const { orders, pointSequence } = this.order

                return pointSequence.map(pointId => {
                    const foundedOrder = orders.find(order => order.pointIdUuid === pointId)

                    if (typeof foundedOrder !== 'undefined') return foundedOrder
                })
            },

            // getMultiOrderComment() {
            //     let multiOrderComment = ''
            //     const { orders } = this.order

            //     if (this.order.isCombined && Array.isArray(orders)) {
            //         orders.forEach(childOrder => {
            //             multiOrderComment += childOrder.descr === '' ? '' : `<b>${childOrder.descr.replace('\n', ' ')}</b><br>`
            //         })
            //     }

            //     return multiOrderComment
            // },

            couriersList() {
                return this.couriers.map(courier => {
                    const role = courier.user?.role
                    const roleStr = role && role !== 'courier' ? ' (стал ' + (role === 'operator' ? 'оператором' : 'точкой')  + ')' : ''
                    return {
                        id: courier.id,
                        name: this.getCourierName(courier) + roleStr }
                })
            },
        },
        watch:{
            orderDialog: function(newValue, oldValue) {
                if(!newValue) {
                    this.$emit('close')
                }
            }
        },
        data() {
            return {
                order: null,
                point: null,
                promptDelete: false,
                dialogCourierComplete: false,
                config,
                orderUpdateFlag: false,
                timeTake: 0,
                timeDelivery: 0,
                orderDialog: true,
                selectedCourierId: null,
                multiOrderPointsDarall: [],
                isFoundCoordinates: false,
                loadingDarallSend: false,
                darallPriceCourier: 0,
                darallPricePoint: 0,
                darallDateReady: null,
                darallDateDelivery: null,
                promptDarallSend: false,
                promptDistance: false,
                distanceUpdateFlag: false,
                promptDistanceValue: 0,
            }
        },

        created() {
            this.order = JSON.parse(JSON.stringify(this.data))
            this.point = this.points.find(point => point.id === this.order.pointId)

            console.log('ORDER EDIT name', this.getOrderName(this.order, this.point))
            console.log('ORDER EDIT order', this.order)
            console.log('ORDER EDIT point', this.point)

            this.orderTimesReload(this.order)

            if (window.outerWidth < 600) {
                this.disableAppScroll()
            }
        },

        beforeDestroy() {
            if (window.outerWidth < 600) {
                this.enableAppScroll()
            }
        },

        mount() {
        },

        methods: {
            setLabel(order) {
                return `Комментарий ${order.address}`
            },

            disableAppScroll() {
                setTimeout(() => {
                    this.scrollY = window.scrollY
                    const app = document.getElementById('app')
                    if (app) {
                        app.style.overflow = 'hidden'
                    }
                }, 500)
            },
            enableAppScroll() {
                const app = document.getElementById('app')
                if (app) {
                    app.style.overflow = ''
                }
                window.scrollTo({ top: this.scrollY })
            },

            orderTimesReload(order) {
                const distance = order.distance ?? 0

                this.timeTake = 30 // 30 минут
                this.timeDelivery = 30 // 30 минут

                if (this.settings.timeTake) {
                    this.timeTake = this.settings.timeTake
                }
                if (this.settings.timeDelivery) {
                    this.timeDelivery = this.settings.timeDelivery
                }

                if (this.settings.timesByDistance) {
                    if (this.settings.distanceTimes && this.settings.distanceTimes.length) {
                        for (let times_value of this.settings.distanceTimes) {
                            if (distance >= times_value[0]) {
                                this.timeTake = times_value[1]
                                this.timeDelivery = times_value[2]
                            }
                        }
                    }
                }
            },

            onInputPriceCourier(value) {
                console.log('onChangePriceCourier', value)
                if (!value) value = 0
                this.order.feeCourier = parseInt(value)
            },

            onInputPricePoint(value) {
                if (!value) value = 0
                this.order.feePoint = parseInt(value)
            },

            onClickAddressFound() {
                this.priceUpdate()
            },

            onClickTimesUpdate() {
                if (this.order.isNearest) {
                    const dateReady = moment()
                    dateReady.add(this.timeTake, 'm')
                    this.order.dateReady = dateReady.utc().format()
                    const dateDelivery = dateReady
                    dateDelivery.add(this.timeDelivery, 'm')
                    this.order.dateDelivery = dateDelivery.utc().format()
                } else {
                    const dateReady = moment(this.order.dateDelivery)
                    dateReady.subtract(this.timeDelivery, 'm')
                    this.order.dateReady = dateReady.utc().format()
                }
                this.notifyToast('Времена обновлены успешно', { color: 'success', timeout: 4000 })
            },

            onClickDistance() {
                this.promptDistanceValue = this.distanceNumber(this.order.distance)
                this.promptDistance = true
            },

            promptDistanceYes() {
                const distance = this.promptDistanceValue * 1000

                if (distance) {
                    this.distanceUpdateFlag = false
                    this.$store.dispatch('organization_price_distance', {
                            distance,
                        })
                        .then(res => {
                            if (res.data.success) {
                                this.order.feePoint = res.data.pricePoint ?? 0
                                this.order.feeCourier = res.data.priceCourier ?? 0
                                this.order.distance = distance
                                this.promptDistance = false
                                this.notifyToast('Стоимость доставки по расстоянию обновлена успешно', { color:'success', timeout: 4000 })
                                this.promptDistance = false
                            } else {
                                this.notifyToast('Не удалось загрузить стоимость доставки', { color:'error', timeout: 10000 })
                            }
                            this.distanceUpdateFlag = false
                        })
                        .catch(err => {
                            this.distanceUpdateFlag = false
                            this.notifyToast('Ошибка запроса', { color:'error', timeout: 10000 })
                        })
                } else {
                    this.notifyToast('Введите значение расстояния в километрах', { color:'error', timeout: 10000 })
                }
            },


            onClickRoute() {
                this.$store.dispatch('open_route', { point: this.point, order: this.order })
            },

            promptDeleteYes() {
                this.$emit('delete')
                this.$emit('close')
            },

            onClickClose() {
                this.$emit('close')
            },

            onClickDelete() {
                this.promptDelete = true
            },

            onClickSave() {
                if (!this.order.latitude || !this.order.longitude) {
                    this.notifyToast('Укажите место доставки', {color:'error', timeout: 4000})
                    return
                }
                this.$emit('save', { order: this.order } )
                this.$emit('close')
            },

            onClickCopy() {
                this.$emit('copy', this.order)
                this.$emit('close')
            },

            updateDarallDates() {
                if (this.order.isNearest) {
                    this.darallDateReady = this.timeTake ? moment().add(this.timeTake, 'seconds') : null
                    this.darallDateDelivery = this.timeDelivery && this.darallDateReady ? moment(this.darallDateReady).add(this.timeDelivery, 'seconds') : null
                } else {
                    this.darallDateReady = this.order.dateReady ? moment(this.order.dateReady) : null
                    this.darallDateDelivery = this.order.dateDelivery ? moment(this.order.dateDelivery) : null
                }
            },

            async onClickSend() {
                console.log('onOrderSend', this.order)

                if (!this.order.isCombined) {
                    if (this.order.distance) {
                        this.promptDarallSend = true
                        this.loadingDarallSend = true
                        this.$store
                            .dispatch('organization_price_distance', {
                                organizationId: config.darallOrganizationId,
                                distance: this.order.distance,
                            })
                            .then(res => {
                                if (res.data.success) {
                                    this.darallPricePoint = res.data.pricePoint ?? 0
                                    this.darallPriceCourier = res.data.priceCourier ?? 0
                                    this.timeTake = res.data.timeTake
                                    this.timeDelivery = res.data.timeDelivery
                                    this.updateDarallDates()
                                    // this.notifyToast('Цены на доставку загружены успешно', { color: 'success', timeout: 5000 })
                                } else {
                                    this.notifyToast(`Не удалось загрузить цены на доставку - ${res.data.msg}`, { color: 'success', timeout: 5000 })
                                    this.promptDarallSend = false
                                }
                                this.loadingDarallSend = false
                            })
                            .catch(err => {
                                this.loadingDarallSend = false
                                this.promptDarallSend = false
                                this.notifyToast(`Ошибка загрузки цен на доставку - ${err.message}`, { color: 'success', timeout: 5000 })
                            })
                    } else {
                        this.notifyToast('Не удалось определить расстояние доставки', { color: 'success', timeout: 5000 })
                    }
                // } else {
                //     this.promptDarallSend = true
                //     this.multiOrderPointsDarall = data.multiOrderPointsDarall
                //     this.darallPriceCourier = 0
                //     this.darallPricePoint = 0
                //     this.darallDateReady = null
                //     this.darallDateDelivery = null
                //     if (this.order.distance) {
                //         this.loadingDarallSend = true
                //         this.$store
                //             .dispatch('price_distance', {
                //                 operator_tid: config.darall_operator_tid,
                //                 distance: this.order.distance,
                //             })
                //             .then(res => {
                //                 if (res.data.success) {
                //                     this.darallPriceCourier = res.data.priceCourier 
                //                         ? res.data.priceCourier + (this.$store.state.settings.extraPointFee ? this.$store.state.settings.extraPointFee * (this.order.orders.length - 1) : 0) 
                //                         : 0
                //                     this.darallPricePoint = res.data.pricePoint 
                //                         ? res.data.pricePoint + (this.$store.state.settings.extraPointFee ? this.$store.state.settings.extraPointFee * (this.order.orders.length - 1) : 0) 
                //                         : 0
                //                     this.timeTake = res.data.timeTake
                //                     this.timeDelivery = res.data.timeDelivery
                //                     this.updateDarallDates()
                //                 }
                //                 this.loadingDarallSend = false
                //             })
                //             .catch(err => {
                //                 this.loadingDarallSend = false
                //             })
                //     }
                }
            },

            promptDarallSendYes() {
                this.loadingDarallSend = true
                this.promptDarallSend = false

                if (this.point.settings.darallPointIdNum) {
                    this.$store
                        .dispatch('order_send', {
                            orderId: this.order.id,
                            dateReady: this.darallDateReady.toDate(),
                            dateDelivery: this.darallDateDelivery.toDate(),
                            feePoint: this.darallPricePoint,
                            feeCourier: this.darallPriceCourier,
                        })
                        .then(res => {
                            if (res.data.success) {
                                this.notifyToast('Заказ отправлен оператору Darall успешно', { color: 'success', timeout: 5000 })
                            } else {
                                this.notifyToast(`Не удалось отправить заказ: ${res.data.msg}`, { color: 'error', timeout: 15000 })
                            }
                            this.loadingDarallSend = false
                            this.$emit('send-complete', { success: res.data.success })
                        })
                        .catch(err => {
                            this.loadingDarallSend = false
                            this.notifyToast(`Ошибка: ${err.message}`, { color: 'error', timeout: 15000 })
                            this.$emit('send-complete', { success: false })
                        })
                } else {
                    this.notifyToast('Не удалось определить точку у оператора Darall, которой отправляется заказ.', { color: 'error', timeout: 15000 })
                }
            },

            // promptDarallSendYesMultiOrder() {
            //     this.promptDarallSend = false

            //     if (this.multiOrderPointsDarall.length === this.order.orders.length) {
            //         this.order.courier_tid = null
            //         this.order.date_accept = null
            //         this.order.date_take = null
            //         this.order.date_complete = null
            //         this.order.status = config.order.status.new

            //         this.updateDarallDates()
            //         this.order.date_ready = this.darallDateReady.toDate()
            //         this.order.date_delivery = this.darallDateDelivery.toDate()
                    
            //         for (let i = 0; i < this.order.orders.length; i += 1) {
            //             const index = i
            //             const order = this.order.orders[i]

            //             if (index < this.order.orders.length - 1) {
            //                 this.order.orders[i].fee_courier = +((+order.pay_cost / +this.order.pay_cost) * this.darallPriceCourier).toFixed(0)
            //                 this.order.orders[i].fee_point = +((+order.pay_cost / +this.order.pay_cost) * this.darallPricePoint).toFixed(0)
            //             } else {
            //                 this.order.orders[i].fee_courier = +this.darallPriceCourier - +this.order.orders.reduce((acc, e, index) => {
            //                     if (index !== this.order.orders.length - 1) acc += e.fee_courier

            //                     return acc
            //                 }, 0)

            //                 this.order.orders[i].fee_point = +this.darallPricePoint - +this.order.orders.reduce((acc, e, index) => {
            //                     if (index !== this.order.orders.length - 1) acc += e.fee_point

            //                     return acc
            //                 }, 0)
            //             }
            //         }

            //         const textPartnerSend = `отправлен из личного кабинета партнера (${this.timeToString()})`

            //         this.order.orders = this.order.orders.map(e => {
            //             const newPoint = this.multiOrderPointsDarall.find(el => el.orderId === e.id)

            //             return {
            //                 ...e,
            //                 oldPointUuid: e.pointIdUuid,
            //                 point_id: typeof newPoint !== 'undefined' ? +newPoint.darallPointIdNum : null,
            //                 point_tid: null,
            //                 courier_tid: null,
            //                 date_accept: null,
            //                 date_take: null,
            //                 date_complete: null,
            //                 status: config.order.status.new,
            //                 date_ready: this.order.date_ready,
            //                 date_delivery: this.order.date_delivery,
            //                 descr: e.descr && e.descr !== '' ? e.descr += `, ${textPartnerSend}` : textPartnerSend
            //             }
            //         })

            //         this.$store
            //             .dispatch('send_multi_order', this.order)
            //             .then(res => {
            //                 if (res.data.success) {
            //                     console.log('res.data', res.data)
            //                     this.updateData()
            //                     this.notifyToast('Мультизаказ отправлен оператору Darall успешно.', { color: 'success', timeout: 5000 })
            //                 } else {
            //                     this.notifyToast('Не удалось сохранить отправляемый мультизаказ.', { color: 'error', timeout: 15000 })
            //                 }
            //             })
            //             .catch(err => {
            //                 console.log('order_set error', err.name, err.message)
            //             })
            //     } else {
            //         this.notifyToast('Не удалось определить точку у оператора Darall, которой отправляется заказ.', { color: 'error', timeout: 15000 })
            //     }
            // },
            
            onSelectCourier(item) {
                this.selectedCourierId = item.id
            },
            
            onClickCourierComplete() {
                this.dialogCourierComplete = false
                this.orderUpdateFlag = true

                this.$store
                    .dispatch('order_complete', {
                        orderId: this.order.id,
                        courierId: this.selectedCourierId,
                    })
                    .then(res => {
                        if (res.data.success) {
                            this.order.courierId = this.selectedCourierId
                            this.order.status = this.OrderStatus.delivered
                            // this.$emit('send-complete', { success: res.data.success })

                            this.notifyToast('Курьер назначен и заказ доставлен успешно', { color: 'success', timeout: 5000 })
                        } else {
                            console.log('order_complete failure', res.data.msg)
                            this.notifyToast(`Не удалось назначить курьера - ${res.msg}`, { color: 'error', timeout: 15000 })
                        }
                        this.orderUpdateFlag = false
                    })
                    .catch(err => {
                        console.log('point_address error', err)
                        this.notifyToast(`Ошибка: ${err.message}`, { color: 'error', timeout: 15000 })
                        this.orderUpdateFlag = false
                    })
            },

            distance(d) {
                if (!d) d = 0
                // console.log('distance', d)
                const distance = parseFloat(d)
                if (distance) {
                    return (distance / 1000).toFixed(1) + ' км'
                }
                return '--'
            },

            orderPrice(p) {
                if (!p) p = 0
                const price = parseInt(p)
                if (price) {
                    return price
                }
                return '--'
            },

            distanceNumber(d) {
                if (!d) d = 0
                const distance = parseFloat(d)
                if (distance) {
                    return (distance / 1000).toFixed(1)
                }
                return 0
            },

            priceUpdate(latitude, longitude) {
                this.order.feeCourier = 'Поиск...'
                this.order.feePoint = 'Поиск...'
                this.order.distance = 'Поиск...'

                const pointId = this.point.id
                let data

                if (latitude && longitude) {
                    data = { pointId, latitude, longitude }
                } else {
                    data = { pointId, address: this.order.address }
                }

                this.orderUpdateFlag = true
                this.$store
                    .dispatch('order_price', data)
                    .then(res => {
                        if (res.data.success) {
                            this.order.feePoint = res.data.price.point

                            const courier = this.couriers.find(courier => courier.id == this.order.courierId)
                            if (courier && courier.tariff > 0) {
                                this.order.feeCourier = courier.tariff
                            } else {
                                this.order.feeCourier = res.data.price.courier
                            }

                            this.order.latitude = res.data.latitude
                            this.order.longitude = res.data.longitude
                            this.order.distance = res.data.distance

                            this.orderTimesReload(this.order)

                            this.notifyToast('Адрес и стоимость доставки обновлены успешно', { color: 'success', timeout: 5000 })
                        } else {
                            console.log('point_address failure', res.data.msg)
                            this.notifyToast('Не удалось обновить - не найден адрес', { color: 'error', timeout: 15000 })
                        }
                        this.orderUpdateFlag = false
                    })
                    .catch(err => {
                        console.log('point_address error', err)
                        this.notifyToast('Ошибка: ' + err.message, { color: 'error', timeout: 15000 })
                        this.orderUpdateFlag = false
                    })
            },

            onAddressWidgetUpdate(data) {
                if (data.address) {
                    this.order.address = data.address
                }

                if (data.latitude !== this.order.latitude || data.longitude !== this.order.longitude) {
                    this.priceUpdate(data.latitude, data.longitude)
                }
            },
        },
    }
</script>

<style lang="stylus">
    .input-price .mdi-currency-rub {
        font-size: 18px !important;
    }
    // .input-price .v-input__prepend-outer {
    //     margin-right: 0 !important;
    // }
    .btn-darall {
        font-size: 13px !important;
    }
</style>


<style lang="stylus" scoped>
    #dialog-order-edit
    //     overflow: hidden;
    //     position: fixed;
    //     width: 100%;
    //     height: 100%;

    .input-price {
        width: 140px;
    }

    .order-dialog
        height 100%
        padding-top 10px !important

    .btn-update {
        height: 24px !important;
        min-width: 168px !important;
    }
</style>
